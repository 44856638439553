import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/sass-control-directives",
  "date": "2015-09-05",
  "title": "SASS CONTROL DIRECTIVES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Sass control directives helps creating libraries for reuse and distribution, there by contribute to writing leaner Sass. Advanced frameworks use mixins. These mixins use directives such as @if, @for, each and @while."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`These control directives will keep you from writing repetitive style declaration and condition logic, that might be the case with CSS.`}</p>
    <h2>{`@if`}</h2>
    <p>{`The `}<strong parentName="p">{`@if`}</strong>{` control directive is useful conditional styling.`}</p>
    <p>{`If the expression returns anything other than `}<inlineCode parentName="p">{`false`}</inlineCode>{`, it will process its block of styles.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@mixin setBorderColor($state) {
    @if $state == 'important' {
        border-color: red;
    }
    @else {
        border-color: black;
    }
}
`}</code></pre>
    <p>{`You can use this mixin as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.class-name {
   @include setBorderColor(important);
}
`}</code></pre>
    <p>{`This will give us:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.class-name {
   border-color: red;
}
`}</code></pre>
    <h2>{`@for`}</h2>
    <p>{`  The `}<strong parentName="p">{`@for`}</strong>{` directive is a loop that has a start point and loops through to an endpoint. You can set @for to either cycle through the loop, or run from a starting point to an ending point.`}</p>
    <p>{`The loop syntax is as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@for $i from 1 through 12 {
    .column-#{$i} {
        width: 100% / 13 - $i;
    }
}
`}</code></pre>
    <p>{`Using `}<strong parentName="p">{`through`}</strong>{` will run the loop from the first iteration through the end. Using `}<strong parentName="p">{`to`}</strong>{` will run each iteration in the loop until, but not including, the end iteration.`}</p>
    <h2>{`@each`}</h2>
    <p><inlineCode parentName="p">{`@each`}</inlineCode>{` loops through Sass variable lists to generate CSS.`}</p>
    <p>{`The syntax looks like `}<inlineCode parentName="p">{`@each $var in`}</inlineCode>{`.`}</p>
    <p>{`Lets look at an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$align-list: center, left, right;

@each $align in $align-list {
    .txt-#{$align} {
        text-align: $align;
    }
}
`}</code></pre>
    <p>{`The cimpiled CSS will look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}
`}</code></pre>
    <h2>{`@while`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`@while`}</inlineCode>{` directive works similiarly to the `}<inlineCode parentName="p">{`@for`}</inlineCode>{` directive, but instead of going through a list of values, the `}<inlineCode parentName="p">{`@while`}</inlineCode>{` directive will run until the value returns `}<strong parentName="p">{`false`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$btn-sizes: 6;
$btn-padding: 4px;

@while $btn-sizes > 0 {
  .btn-pad-#{$btn-sizes / 2} {
    padding: $btn-padding + $btn-sizes $btn-padding * $btn-sizes;
  }
  $btn-sizes: $btn-sizes - 2;
}
`}</code></pre>
    <p>{`This code will be compiled into:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.btn-pad-3 {
    padding: 10px 24px;
}

.btn-pad-2 {
    padding: 8px 16px;
}

.btn-pad-1 {
    padding: 6px 8px;
}
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`All of these directives can end up saving you quite a bit of repetitive CSS. But use them sparingly, only when necessary.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      